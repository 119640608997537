import React, { FC, memo } from "react";
import { state } from "./state";
import { useSnapshot } from "valtio";
import Emoji from "./Emoji"

export const Footer: FC = memo(() => {
  const {emoji} = useSnapshot(state)
  if (!emoji) return <></>
  return (
    <div className="emoji-picker-footer">
      {<Emoji emoji={emoji}/>}
      <div className="emoji-picker-name">
        {emoji.name}
      </div>
    </div>     
  )
})