import React, { FC, useState, KeyboardEvent, MouseEvent, memo, useCallback } from "react";
import Emoji from "./Emoji"
import { state } from "./state";
import { useSnapshot } from "valtio";

export type NavbarProps = {
  [key: string]: any
}

export const Navbar: FC<NavbarProps> = memo(({...props}) => {

  const { emojis } = useSnapshot(state)

  // Define event handler for select in navbar element.
  const onNavbarSelect = useCallback(
    (category: string) => {
      const { list, rowMeta: { rowRanges }} = state
      let range = rowRanges.find(range => range.key === category)
      console.log("Hi", list, range)
      if (list && range) list.scrollToItem(range.from, "start");
    },
    [],
  )

  // roving tabindex
  const [index, setIndex] = useState(0);
  
  const onNavbarKeyDown = (event: KeyboardEvent) => {
    const emojis = state.emojis
    switch (event.key) {
      case 'Enter': 
        return onNavbarSelect(Object.keys(emojis)[index]);

      case 'ArrowUp':
      case 'ArrowLeft':
        return index > 0 && setIndex(index => index - 1);

      case 'ArrowDown':
      case 'ArrowRight':
        return index < Object.keys(emojis).length - 1 && setIndex(index => index + 1)

      case 'Home':
        return index > 0 && setIndex(0);
      case 'End':
        return index < Object.keys(emojis).length - 1 && setIndex(Object.keys(emojis).length - 1)
    }
  }

  console.log("index", index)

  const onClick = useCallback(
    (index: number, category: string) => (event: MouseEvent<HTMLElement>) => {
      setIndex(index)
      onNavbarSelect(category)
    },
    [],
  )

  const onMouse = useCallback(
    (index: number, category: string) => (event: MouseEvent<HTMLElement>) => {
      if (event.movementX == 0 && event.movementY == 0) return
      setIndex(index)
    },
    [],
  )


  return (
    <div className="emoji-picker-navbar" {...props} role="tablist" aria-label="emoji categories">
      {Object.entries(emojis).map(([category, list], i) => (
        <button
          className="emoji-picker-navbar-category"
          key={`navbar-${category}`}
          onMouse={onMouse(i, category)}
          onClick={onClick(i, category)}
          onKeyDown={onNavbarKeyDown}
          role="tab"
          aria-label="category"
          aria-selected={i === index}
          tabIndex={i === index ? 0 : -1}
        >
          <Emoji emoji={list[0]}/>
        </button>
      ))}
    </div>  
  )
})