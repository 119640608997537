import { state } from "./state";
import { ref } from "valtio";

/**
 * Converts from unified to native representation of an emoji.
 * @param unified unified representation
 */
export function unifiedToNative(unified: string) {
  const codePoints = unified.split('-').map(u => parseInt(u, 16));
  return String.fromCodePoint.apply(String, codePoints);
}

/**
 * Measures the pixel width of a scrollbar.
 * source: https://github.com/sonicdoe/measure-scrollbar.
 */
export function measureScrollbar(): number {
  if (typeof document == 'undefined') return 0;
  const div = document.createElement('div');
  div.style.cssText = "width:100px; height:100px; overflow:scroll; position:absolute; top:-9999px";
  document.body.appendChild(div);
  const scrollbarWidth = div.offsetWidth - div.clientWidth;
  document.body.removeChild(div);
  return scrollbarWidth;
}

export type rowRange = { key: string; from: number; to: number; length: number }

/**
 * Calculates the number of rows when key and array are flattened, along
 * with an array of ranges to map an index back to key.
 * @param data key array mapping
 * @param perRow number of elements to chunk array into
 */
export function calcCountAndRange(data: Record<string, any[]>, perRow: number) {
  console.log("calc")
  let rowCount = 0, rowRanges: rowRange[] = [];
  Object.entries(data).forEach(([key, array]) => {
    if (array.length === 0) return;
    let from = rowCount, to = rowCount + 1 + Math.ceil(array.length / perRow);
    rowRanges.push({key, from, to, length: array.length});
    rowCount = to;
  })
  return {rowCount, rowRanges};
}

// Returns true if objects shallowly differ.
export function shallowDiffer(prev: Object, next: Object): boolean {
  for (let attribute in prev) { if (!(attribute in next)) { return true; }}
  for (let attribute in next) { if (prev[attribute] !== next[attribute]) { return true; }}
  return false;
}

// Trailing throttle function.
export function throttleIdleTask(callback: Function) {
  // @ts-ignore
  const idleHandler = typeof requestIdleCallback === 'function' ? requestIdleCallback : setTimeout;
  let running = false, argsFunc: any;
  return function throttled(...args: any[]) {
    argsFunc = args;
    if (running) { return; }
    running = true;
    idleHandler(() => {
      running = false; 
      callback.apply(null, argsFunc);
    })
  }
}


export function focusedElement(grid: HTMLElement): HTMLElement | null {
  return grid.querySelector("[tabindex='0']")
}

export function getGridPosition(element: HTMLElement) {
  return {
    row: +element.getAttribute("aria-rowindex"),
    col: +element.getAttribute("aria-colindex"),
  }
}

export function getGridElement(grid: HTMLElement, {row, col}) {
  row = grid.querySelector(`[role="row"][aria-rowindex="${row}"]`)

  return grid.querySelector(`[aria-rowindex="${row}"] [aria-colindex="${col}"]`)
}

export function getNextRow(grid, row, dir) {
  row = row + dir
  let nextRow = grid.querySelector(`[role="row"][aria-rowindex="${row}"]`)
  if (!nextRow) {
    row = row + dir
    nextRow = grid.querySelector(`[role="row"][aria-rowindex="${row}"]`)
  }
  return {row, colCount: nextRow?.childElementCount}
}

export function setNextEmoji({row, col}) {
  let emoji = state.gridMap[`${row}:${col}`]

  const prev = state.emoji
  prev && delete state.selected[prev.unicode]

  state.emoji = ref(emoji)
  state.selected[emoji.unicode] = true

  if (state.grid?.contains(document.activeElement)) {
    const gridcell: HTMLElement | null = state.grid.querySelector(`[aria-rowindex="${row}"] [aria-colindex="${col}"]`)
    gridcell?.focus()
  }
}