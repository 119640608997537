import { proxy, ref } from 'valtio'
import type { FixedSizeList as VirtualList } from 'react-window';

import { calcCountAndRange } from './utils'
import type { skinToneModifier, EmojiObject } from './types';

import { derive } from 'valtio/utils'
import { devtools } from 'valtio/utils';

export type EmojiPickerState = {
  emojis: Record<string, EmojiObject[]>
  emojisPerRow: number

  rowMeta: ReturnType<typeof calcCountAndRange>

  query: string
  search: (query: string) => void
  index: any

  emoji?: EmojiObject // selected emoji
  selected: Record<string, boolean>

  skinToneModifier?: skinToneModifier

  list?: VirtualList
  grid?: HTMLElement

  gridMap: Record<string, EmojiObject>
}

export const state = proxy<EmojiPickerState>({
  emojis: ref(Object.create(null)),
  emojisPerRow: 0,

  rowMeta: {rowCount: 0, rowRanges: []},

  selected: {},

  list: undefined,
  grid: undefined,

  gridMap: ref(Object.create(null)),

  query: "",

  /**
   * TODO: Replace in-memory search with indexeddb index.
   * See as an example: https://github.com/nolanlawson/emoji-picker-element
   */
  search: (query: string) => {
    state.query = query
    const results = state.index.search(query)
    console.log("result", query, results.map(({item}) => item))
  },

  index: undefined
})

// devtools(state, "state")

