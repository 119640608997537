import React, { forwardRef, useCallback, useState, useImperativeHandle, useMemo, useLayoutEffect, useRef, Ref, useReducer, KeyboardEvent, MouseEvent, useEffect } from "react"
import { EmojiObject } from "./types";
import { measureScrollbar, calcCountAndRange } from './utils'
import { useWindowEvent } from "./hooks";

import { Navbar } from "./Navbar";
import { Footer } from "./Footer";
import { Scroll } from "./Scroll";
import { state } from "./state";

import { ref, useSnapshot } from "valtio"

import Fuse from "fuse.js"

type EmojiPickerProps = {
  emojiData: Record<string, EmojiObject[]>;
  emojiSize?: number;
  onEmojiSelect?: (emoji: EmojiObject, event: KeyboardEvent | MouseEvent) => void;
  showNavbar?: boolean;
  showFooter?: boolean;
  emojisPerRow?: number;
  numberScrollRows?: number;
  onKeyDownScroll?: Function;
  collapseCategoriesOnSearch?: boolean;
  collapseHeightOnSearch?: boolean;
  theme?: "system" | "light" | "dark";
}

// Define public methods accessible via ref.
export interface EmojiPickerRef {
  search: (query: string) => void;
  emojis: Record<string, EmojiObject[]>;
  focusedEmoji: EmojiObject | null;
  onKeyDownScroll: (event: KeyboardEvent<HTMLElement>) => void;
}

export const EmojiPicker = forwardRef(({
  emojiData = {},
  emojiSize = 36,
  numberScrollRows = 12,
  onEmojiSelect = (emoji: EmojiObject) => console.log(emoji), 
  showNavbar = false,
  showFooter = false,
  emojisPerRow = 9, 
  collapseCategoriesOnSearch = true, 
  collapseHeightOnSearch = true, 
  theme = "system",
  onKeyDownScroll: onKeyDown
}: EmojiPickerProps, emojiPickerRef: Ref<EmojiPickerRef>) => {

  useEffect(
    () => {
      state.emojis = ref(emojiData)
      state.emojisPerRow = emojisPerRow
      state.rowMeta = calcCountAndRange(state.emojis, state.emojisPerRow)
      const fuse = new Fuse(Object.values(emojiData).flat(), {keys: ["name", "keywords"], includeScore: true, threshold: 0.25})
      state.index = ref(fuse)
    },
    [emojiData, emojisPerRow]
  )

  // Make internal state and methods as publicly accessible via ref.
  useImperativeHandle(emojiPickerRef, () => ({

  }) as EmojiPickerRef)

  /**
   * Compute width on window resize.
   */

  const getWidths = useCallback(
    () => {
      const scrollbarWidth = measureScrollbar()
      return {
        scrollbarWidth,
        width: `calc(${emojiSize}px * ${emojisPerRow} + 1em + ${scrollbarWidth}px)`
      }
    },
    [emojiSize, emojisPerRow]
  )

  const [width, setWidth] = useState(getWidths);

  useWindowEvent(
    'resize',
    () => setWidth(getWidths),
    true
  );

  const ScrollProps = {
    emojisPerRow: emojisPerRow!,
    emojiSize,
    numberScrollRows,
    onEmojiSelect,
    collapseHeightOnSearch,
  }

  return (
    <div 
      className={`emoji-picker emoji-picker-${theme}`} 
      style={{width: width.width}}
    >
      {showNavbar && <Navbar style={{marginRight: width.scrollbarWidth}}/>}
      <Scroll {...ScrollProps}/>
      {showFooter && <Footer/>}
    </div>
  )
});