import React, { FC, useEffect, useRef, Ref, useState, memo, forwardRef, MutableRefObject, CSSProperties, MouseEvent, useCallback } from "react";
import { FixedSizeList as VirtualList } from './FixedSizeList';
import InfiniteLoader from "react-window-infinite-loader";
import type { EmojiObject } from "./types"
import { shallowDiffer, itemRange, getGridElement, getNextRow, setNextEmoji } from './utils'
import Emoji from "./Emoji";
import { useSnapshot, ref } from "valtio";
import { state } from "./state";

import { focusedElement, getGridPosition } from './utils'

type ScrollProps = {
  emojisPerRow: number, 
  emojiSize: number,
  numberScrollRows: number,
  collapseHeightOnSearch: boolean,
  onEmojiSelect: (emoji: EmojiObject, event: KeyboardEvent | MouseEvent) => void;
  onKeyDownScroll: Function;
}

export const Scroll: FC<ScrollProps> = memo(({
  emojisPerRow, 
  emojiSize, 
  numberScrollRows, 
  collapseHeightOnSearch, 
  onEmojiSelect, 
}) => {

  const { emojis, query } = useSnapshot(state)
  console.log("QUERy", query)
  
  const [arrayOfRows, setArrayOfRows] = useState<Record<number, JSX.Element>>({});
  const infiniteLoaderRef = useRef<InfiniteLoader>(null);

  // Define event handlers in scroll element.

  const onClick = useCallback(
    (emoji: EmojiObject) => (event: MouseEvent<HTMLElement>) => {
      event.preventDefault(); // MDN docs: keep the focus from leaving the HTMLElement
      event.currentTarget.focus()
      onEmojiSelect(emoji, event);
    }, 
    [onEmojiSelect],
  )
  
  const onMouse = useCallback(
    (emoji: EmojiObject) => (event: MouseEvent<HTMLElement>) => {
      const prev = state.emoji
      if (event.movementX == 0 && event.movementY == 0 || emoji == prev) return;

      /**
       * If you call HTMLElement.focus() from a mousedown event handler, 
       * you must call event.preventDefault() to keep the focus from leaving the HTMLElement.
       * 
       * https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/focus
       */

      event.preventDefault();
      event.currentTarget.focus()

      prev && delete state.selected[prev.unicode]

      state.emoji = ref(emoji)
      state.selected[emoji.unicode] = true
    },
    [],
  )

  const loadMoreItems = useCallback(
    (startIndex: number, endIndex: number) => {

      const { rowMeta: { rowRanges }, emojis } = state

      const nextArrayOfRows = {}
      let i = startIndex, range: itemRange | undefined;
      while (i <= endIndex) {

        range = rowRanges.find(range => range.from <= i && i < range.to);
        if (range === undefined) break;

        for (let rowIndex = i; rowIndex < Math.min(range.to, endIndex + 1); rowIndex++) {
          if (rowIndex == range.from) {
            nextArrayOfRows[rowIndex] = (
              <div 
                className="emoji-picker-category-title" 
                id={range.key}
                role="columnheader"
                aria-rowindex={rowIndex + 1} 
                aria-colspan={emojisPerRow}
              >
                {range.key}
              </div>
            )
          } else {

            const offset = rowIndex - range.from;
            const row = emojis[range.key].slice((offset - 1) * emojisPerRow, offset * emojisPerRow)

            row.forEach((emoji: EmojiObject, colIndex: number) => (state.gridMap[`${rowIndex + 1}:${colIndex + 1}`] = ref(emoji)))

            nextArrayOfRows[rowIndex] = (
              <ul 
                className="emoji-picker-category-emoji" 
                role="row" 
                aria-rowindex={rowIndex + 1}
              >
                {row.map((emoji: EmojiObject, colIndex: number) => (
                  <RowItem
                    key={emoji.unicode}
                    onClick={onClick(emoji)}
                    onMouseMove={onMouse(emoji)}
                    role="gridcell"
                    aria-rowindex={rowIndex + 1}
                    aria-colindex={colIndex + 1}
                    emoji={emoji}
                  >
                  </RowItem>
                )) 
                }
              </ul>
            )
          }
        }
        i = range.to;
      }
      setArrayOfRows(prev => Object.assign({}, prev, nextArrayOfRows));
    },
    [emojisPerRow, onClick, onMouse],
  )

  const {rowMeta: {rowCount}} = useSnapshot(state)

  /**
   * In theory, we'd follow ARIA keyboard accessibility. In practice, we follow keyboard
   * navigation in the Discord emoji picker.
   * 
   * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/Grid_Role#keyboard-use
   */

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (!state.grid) return
      const focused = focusedElement(state.grid)
      if (!focused) {
        return
      }

      switch (event.key) {
        case "Enter":
          event.preventDefault()
          state.emoji && onEmojiSelect(state.emoji, event)
          return

        case "ArrowUp": {
          event.preventDefault()
          let {row: prevRow, col} = getGridPosition(focused)
          let {row, colCount} = getNextRow(state.grid, prevRow, -1)
          if (colCount) setNextEmoji({row, col: Math.min(col, colCount)})
          state.list?.scrollToItem(row - 1)
          return
        }

        case "ArrowDown": {
          event.preventDefault()
          let {row: prevRow, col} = getGridPosition(focused)
          let {row, colCount} = getNextRow(state.grid, prevRow, +1)
          if (colCount) setNextEmoji({row, col: Math.min(col, colCount)})
          state.list?.scrollToItem(row - 1)
          return
        }

        case "ArrowLeft": {
          event.preventDefault()
          let {row: prevRow, col} = getGridPosition(focused)
          // Boundary condition
          if (col === 1) {
            let {row, colCount} = getNextRow(state.grid, prevRow, -1)
            if (colCount) setNextEmoji({row, col: colCount})
          } else {
            setNextEmoji({row: prevRow, col: col - 1})
          }
          return
        }


        case "ArrowRight": {
          event.preventDefault()
          let {row: prevRow, col} = getGridPosition(focused)
          // Boundary condition
          if (col === state.grid.querySelector(`[role="row"][aria-rowindex="${row}"]`)?.childElementCount) {
            let {row, colCount} = getNextRow(state.grid, prevRow, +1)
            if (colCount) setNextEmoji({row, col: 1})
          } else {
            setNextEmoji({row: prevRow, col: col + 1})
          }
          return
        }


        case "Home": {
          // Moves focus to the first cell in the row that contains focus.
          if (!event.ctrlKey) {
            col = 1
            setNextEmoji({row, col: 1})
          // Moves focus to the first cell in the first row.
          } else {
            row = 1, col = 1
            setNextEmoji({row: 1, col: 1})
          }
          return 
        }

        case "End": {
          // Moves focus to the last cell in the row that contains focus.
          if (!event.ctrlKey) {
            col = 1
            setNextEmoji({row, col: 1})
          // Moves focus to the last cell in the last row.
          } else {
            row = 1, col = 1
            setNextEmoji({row, col: 1})
          }
        }

        default:
          return;
      }
    },
    [
      onEmojiSelect,
    ],
  )


  return (
    <div 
      className="emoji-picker-scroll" 
      id="emoji-picker-grid"
      role="grid" 
      aria-rowcount={rowCount} 
      aria-colcount={emojisPerRow} 
      onKeyDown={onKeyDown}
      tabIndex={0}
      ref={grid => {
        if (!grid) return
        grid.toJSON = () => {};
        state.grid = ref(grid)
      }}
    >
      {rowCount 
        ? <InfiniteLoader 
            ref={infiniteLoaderRef}
            itemCount={rowCount}
            loadMoreItems={loadMoreItems}
            isItemLoaded={(index: number) => Boolean(arrayOfRows[index])}
            minimumBatchSize={1}
            threshold={numberScrollRows}
          >
            {({onItemsRendered, ref: listRef}) => (
              <VirtualList
                onItemsRendered={onItemsRendered} 
                ref={list => {
                  if (!list) return
                  list.toJSON = () => {};
                  state.list = ref(list);
                  listRef(list); 
                }}
                itemCount={rowCount} 
                itemData={arrayOfRows}
                itemSize={emojiSize} 
                height={collapseHeightOnSearch ? Math.min(rowCount * emojiSize + 9, numberScrollRows * emojiSize) : numberScrollRows * emojiSize}
                innerElementType={innerElementType}
              >
                {MemoizedRow}
              </VirtualList>
            )}
          </InfiniteLoader>
        : <div className="emoji-picker-category" style={{height: collapseHeightOnSearch ? 'inherit' : '432px'}}>
            <div className="emoji-picker-category-title" style={{height: `${emojiSize}px`}}>No results</div>
          </div>
      }
    </div>
  )
})

const VirtualRow: FC<{index: number, style: CSSProperties, data}> = ({index, style, data}) => {
  return (
    <div className="emoji-picker-virtual-row" style={style}>
      {data[index]}
    </div>
  )
}

/**
 * Memoize rows of the virtualList, only re-rendering when changing in data[index]
 */
const MemoizedRow = memo(VirtualRow, function compareRowProps(prevProps, nextProps) {
  const { style: prevStyle, data: prevData, index: prevIndex, ...prevRest } = prevProps;
  const { style: nextStyle, data: nextData, index: nextIndex, ...nextRest } = nextProps;
  return prevData[prevIndex] === nextData[nextIndex] && !shallowDiffer(prevStyle, nextStyle) && !shallowDiffer(prevRest, nextRest)
});


/**
 * Adds padding to the bottom of virtual list.
 * https://github.com/bvaughn/react-window#can-i-add-padding-to-the-top-and-bottom-of-a-list
 */
const LIST_PADDING_SIZE = 9;
const innerElementType = forwardRef(({style, ...props}: {style: CSSProperties}, ref: Ref<VirtualList>) => (
  // @ts-ignore
  <div ref={ref} style={{...style, height: `${parseFloat(style.height) + LIST_PADDING_SIZE}px`, contain: 'strict'}} 
    {...props}
  />
));

const RowItem = ({emoji, ...props}) => {
  const {[emoji.unicode]: selected} = useSnapshot(state.selected)
  return (
    <li
      tabIndex={selected ? 0 : -1}
      {...props}
    >
      <Emoji 
        emoji={emoji} 
        className={selected ? "emoji-picker-emoji-selected" : ""}
      />
    </li>
  )
}